import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { GreenBtn, md } from '../common'

export default () => {
  return(
  <Wrap>
    <Copy>
      <h1>Watch Repairs</h1>
      <p>Underwood’s is fortunate to have a watch repair shop designed and outfitted by Rolex Watch Co. Underwood’s watch technicians are certified as either CW21 or SAWTA, the two highest level designations in the world, denoting a high level of expertise in their craft.</p>
      <Link to={'/watch-repair'}><GreenBtn>Learn More</GreenBtn></Link>
    </Copy>
  </Wrap>
)}

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/Services/watch-repair_bg.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: white;
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`

const Copy = styled.div`
  width:40%;
  margin-right:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
    text-transform:uppercase;
  }

  ${md} {
    margin: 0;
    width: 90%;
  }
`
