import React, { useState } from 'react'
import { navigate } from '@reach/router' 
import styled from 'styled-components'
import Modal from '../components/modal'
import Contact from '../components/contactForm'
import { GreenBtn, md, colors } from '../common'

export default () => {
  const [show, setShow ] = useState(false)

  function handleClick() {
    setShow(!show)
    if (typeof window !== "undefined") {
      window.scrollTo(0,0)
      if (show) {
        navigate(`${window.location.pathname}${window.location.hash}`)
      }
    }
  }

  return(
  <Wrap>
    <Copy>
      <h1>CORPORATE GIFTS</h1>
      <br />
      <p>Giving the right gift to your staff and clients is important, and Underwood’s makes it easy for you. Whether you choose to honor an employee for their longstanding service with a Rolex watch or a strand of Mikimoto pearls, our staff will assist you from selection to delivery. Our corporate accounts are perfect for milestone employees, retirement gifts, promotional gifts, client gifts and outstanding performance incentives.</p>
      <p><strong>Make an appointment to apply for our corporate program.</strong></p>
      <GreenBtn onClick={handleClick}>Contact Us</GreenBtn>
    </Copy>

    <Modal show={ show } toggle={ handleClick } mobile={ true }>
      <Contact />
    </Modal>
  </Wrap>
)}

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/Services/corporate-gift_bg.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: ${colors.greyDarkest};
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`

const Copy = styled.div`
  width:40%;
  margin-left:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
    color: ${colors.green};
  }

  ${md} {
    margin: 0;
    width: 90%;
  }
`
