import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { GreyBtn, md, colors } from '../common'

export default () => {
  return(
  <Wrap>
    <Copy>
      <h1>FINANCING & LAYAWAY:</h1>
      <p>At Underwood’s, we want to see all of your dreams come true. We understand that fine jewelry, watches, diamonds and rings are big investments and therefore we proudly offer two options to accommodate your financial needs.</p>
      <h2>No Interest if Paid in Full within 12 months</h2>
      <p>Interest will be charged to your account from the purchase date if the purchase balance is not paid in full within the promotional period. <sup>1</sup></p>
      <p css={`font-size:12px;`}><sup>1</sup> The American Gem Society credit card is issued by Wells Fargo Bank, N.A. Special terms apply to qualifying purchases charged with approved credit. Minimum monthly payments are required during the promotional (special terms) period. Interest will be charged to your account from the purchase date at the APR for Purchases if the purchase balance is not paid in full within the promotional period. Paying only the minimum monthly payment will not pay off the purchase balance before the end of the promotional period. For new accounts, the APR for Purchases is 28.99%. If you are charged interest in any billing cycle, the minimum interest charge will be$1.00. This information is accurate as of 04/01/2018 and is subject to change. For current information call us at 1-800-431-5921. Offer expires 12/31/2018.</p>
      <Link to={'/financing'}><GreyBtn>Apply Now</GreyBtn></Link>
      <p>We also offer a layaway program for 10-months, no interest*.</p>
      <p><strong>All you need is 20% down to qualify:</strong></p>
      <br />
      <p>- No credit check required -</p>
      <p>- Minimum monthly payment -</p>
      <p>- Interest free -</p>
      <p>- Stays safe in our store until the balance is paid -</p>
      <br />
      <p>Visit Our Stores for Details.</p>
      <p css={`font-size:11px;`}>*Unpaid monthly statements will accrue a finance charge. Certain items do not qualify for layaway purchases.</p>
    </Copy>
    <div css={`display:flex;flex-direction:row-reverse;margin-right:-8rem;${md}{margin:0;}`}>
      <img css={`width:100%;align-self:end;margin:auto;`} src={'https://s3.amazonaws.com/underwoods/Services/financinf-layaway_img.png'} alt="ring on pen over cash" />
    </div>
  </Wrap>
)}

const Wrap = styled.div`
  background-color: ${colors.green};
  padding: 4rem;
  color: white;
  text-align: center;
  display: flex;

  ${md} {
    padding: 1rem;
    flex-direction:column-reverse;
  }
`

const Copy = styled.div`
  width:60%;
  margin-right:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
    text-transform:uppercase;
  }

  ${md} {
    margin: 0;
    width: 90%;
  }
`
