import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import JoinUs from '../sections/joinUs'
import GreenBar from '../sections/watch-greenBar4'
import Custom from '../sections/serv-custom'
import CorpGift from '../sections/serv-corporate'
import Watch from '../sections/serv-watch'
import Finance from '../sections/serv-finance'
import { PageHead } from '../common'

export default () => (
  <Layout headImg={`https://s3.amazonaws.com/underwoods/header_services.jpg`}>
    <SEO
      title="Services"
      description="At Underwood’s, we offer many services for your jewelry and watch needs. This includes: jewelry repair, watch repair, appraisals, watch batteries, watch sizing, ring sizing and more." />
    <PageHead>
      <h1>SERVICES</h1>
      <p>Thank you for considering Underwood’s for your jewelry and watch repairs. We know that your jewelry is priceless to you and are honored to be trusted with its care. We have two master jewelers and two Rolex trained watchmakers on our staff. Underwood’s is fortunate to have the best repair departments in Jacksonville and Ponte Vedra Beach, FL.</p>
      <p>Each of our locations is setup to handle your jewelry/watch repair needs. A sales associate will take a photo of your piece, give you a receipt and insure it for a value determined by you while it is in our possession. If you would like an estimate before we do any work, we will contact you with a price for you to approve before we begin work. Since every piece of jewelry is unique, each job is looked at individually and handled with extreme care.</p>
    </PageHead>
    <GreenBar />
    <Custom id="custom-jewelry"/>
    <Watch />
    <CorpGift />
    <Finance />
    <JoinUs />
  </Layout>
)
