import React, { useState } from 'react'
import { navigate } from '@reach/router' 
import styled from 'styled-components'
import Modal from '../components/modal'
import Contact from '../components/contactForm'
import { GreenBtn, md, colors } from '../common'

export default () => {
  const [show, setShow ] = useState(false)

  function handleClick() {
    setShow(!show)
    if (typeof window !== "undefined") {
      window.scrollTo(0,0)
      if (show) {
        navigate(`${window.location.pathname}${window.location.hash}`)
      }
    }
  }

  return(
  <Wrap id="custom-jewelry">
    <Copy>
      <h1>CUSTOM DESIGNS</h1>
      <br />
      <p>Can’t find exactly what you’re looking for? Want to add something personal to your engagement ring to make it extra special? Our educated sales staff and jewelers will work with you to create a custom designed piece. You will be involved in every step of the process, working together to create a one-of-a-kind, custom design.</p>
      <p>If you have family heirlooms or jewelry you just don’t wear anymore we can repurpose it into something more beautiful that you’ll treasure again. Create something new and special out of something old!</p>
      <p><strong>How long does a custom design take?</strong><br /> Custom designs can take several weeks, so give yourself plenty of time!</p>
      <p>To custom design jewelry with our staff, click on the store that’s most convenient for you to schedule a consultation:</p>
      <GreenBtn onClick={handleClick}>Schedule A Consultation</GreenBtn>
    </Copy>

    <Modal show={ show } toggle={ handleClick } mobile={ true }>
      <Contact />
    </Modal>
  </Wrap>
)}

const Wrap = styled.div`
  background: url("https://s3.amazonaws.com/underwoods/Services/custom-design_bg.jpg");
  background-size: cover;
  background-position: center top;
  padding: 4rem;
  color: ${colors.greyDarkest};
  text-align: center;

  ${md} {
    padding: 1rem;
  }
`

const Copy = styled.div`
  width:40%;
  margin-left:auto;
  padding: 1rem;
  line-height:24px;

  h1 {
    line-height:32px;
    color: ${colors.green};
  }

  ${md} {
    margin: 0;
    width: 90%;
  }
`
